










































import {Component, Vue} from "vue-property-decorator";
import Seguridad from "@/components/Seguridad.vue";
import Sobre from "@/components/Sobre.vue";
import Portada from "@/components/Portada.vue";
import Locales from "@/components/Locales.vue";
import Informacion from "@/components/Informacion.vue";
import Contacto from "@/components/Contacto.vue";
import Detalle from "@/components/Detalle.vue";
import {Local} from "@/typings/store";

@Component({
  components: {Detalle, Contacto, Informacion, Locales, Portada, Sobre, Seguridad}
})
export default class Inicio extends Vue {
  dialogoDetalle: { model: boolean; local: Local | null } = {
    model: false,
    local: null
  }

  openDialog(local: Local) {
    this.dialogoDetalle.model = true;
    this.dialogoDetalle.local = local;
  }

  closeDialog() {
    this.dialogoDetalle.model = false;
    this.dialogoDetalle.local = null;
  }
}
