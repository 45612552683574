

















































import {Component, Vue} from "vue-property-decorator";

@Component
export default class Informacion extends Vue {
  datos = [
    {
      id: 1,
      text1: "Estructura Metálica",
      text2: "Confeccionado integramente en estructura metálica"
    },
    {
      id: 2,
      text1: "Cubierta de zinc PT40",
      text2: "Todas las bodegas con cubierta de zinc PT40"
    },
    {
      id: 3,
      text1: "Pavimentados con hormigón",
      text2: "Todos los galpones están pavimentados con hormigón armado de 15cm de espesor o poseen asfalto de 6cm"
    },
    {
      id: 4,
      text1: "Energía trifásica",
      text2: "Disponibilidad de energía trifásica y cuentan con oficinas y servicios"
    }
  ]
}
