
























































































import {Component, Emit, Vue} from "vue-property-decorator";
import {Local} from "@/typings/store";

@Component
export default class Locales extends Vue {
  locales: Local[] = [
    {
      id: 1,
      imagenes: [
        "imagen1.jpg",
        "imagen2.jpg",
        "imagen3.jpg",
        "imagen4.jpg",
        "imagen5.jpg",
        "imagen6.jpg",
        "imagen7.jpg",
        "imagen8.jpg",
        "imagen9.jpg",
        "imagen10.jpg",
        "imagen11.jpg",
        "imagen12.jpg"
      ],
      nombre: "ARRIENDO RESTAURANTE INCLUYE DERECHO DE LLAVES",
      direccion: "Avenida Vicuña Mackena 1613, Santiago",
      rol: "3388-003",
      precio: 0,
      descripcion: "Excelente ubicación, RESTAURANTE TOTALMENTE REMODELADO, en plena Vicuña Mackenna casi esquina Maule, a pasos de Metro Ñuble. Incluye derecho de llaves del local con patente de alcohol diurna y nocturna. Equipamiento de restaurante con mesón de autoservicio en acero inoxidable, mesas y sillas para 140 personas, con mobiliario de cocina incluida. Local habilitado con ventana de comida con atención al público a la calle.",
      estado: "Disponible",
      datos: {
        m2Terreno: "210,00 m²",
        m2Construidos: "230,00 m²",
        dimensionesTerreno: "9,55m frente x 23m fondo",
        primerPiso: "210,00 m²",
        altillo: "20,00 m²",
        m2Comedor: "130,00 m²",
        m2Bodega: "20,00 m²",
        m2Cocina: "60,00 m²",
        m2Servicios: "20,00 m²",
        electricidad: "Trifásica 27kv",
        agua: "Agua potable y alcantarillado aguas andinas",
        usosPermitidos: "Uso exclusivo restaurante",
        gas: "Gas natural medidor metrogas",
        equipamientoIncluido: "Mesas, sillas, TV, aire acondicionado, mesón acero inoxidable de autoservicio, refrigeradores y mesones de acero inoxidable"
      },
      observaciones: "Local cuenta con permiso y recepción final del año 1986, certificado resolución sanitaria SESMA, patente de restaurante, patente alcohol diurno y patente alcohol nocturno."
    },
    {
      id: 2,
      imagenes: [
        "imagen1.jpg",
        "imagen2.jpg",
        "imagen3.jpg",
        "imagen4.jpg",
        "imagen5.jpg",
        "imagen6.jpg",
        "imagen7.jpg",
        "imagen8.jpg",
        "imagen9.jpg"
      ],
      posicion: "-33.4700345,-70.628208",
      nombre: "ARRIENDO LOCAL COMERCIAL OFICINAS CON BODEGA",
      direccion: "Calle Guillermo Marconi 1992 - Fray Camilo Henriquez 1991",
      rol: "3388-003",
      precio: 0,
      descripcion: "Excelente ubicación, DESTINO COMERCIAL OFICINAS CON BODEGA, a pasos de Vicuña Mackenna y Metro Ñuble. Apto comercio, importadoras, con 2 oficinas privadas y una gran oficina en planta libre, baño administración. Galpón para almacenamiento y estacionamientos. Área baño y camarines para personal y en segundo piso comedor con altillo para bodegaje. Acceso a 2 calles entrada vehicular de camiones a Fray Camilo Henríquez y acceso peatonal por Guillermo Marconi.",
      estado: "Disponible",
      datos: {
        m2Terreno: "336,00 m²",
        m2Construidos: "353,00 m²",
        dimensionesTerreno: "8,40m frente x 40,00m fondo",
        primerPiso: "336,00 m²",
        altillo: "17,40 m²",
        m2Oficina: "74,00 m²",
        m2Bodega: "230,00 m²",
        m2Servicios: "33,77 m²",
        electricidad: "2 Medidores BT1",
        agua: "2 medidores agua potable y alcantarillado aguas andinas",
        gas: "Gas natural medidor metrogas",
        usosPermitidos: "Comercio en general excepto los indicados, Talleres artesanales del listado de giros CIIU 4a",
        usosProhibidos: "Venta de repuestos y partes de vehiculos motorizados, Venta de motores y maquinarias",
        equipamientoIncluido: "Ninguno"
      },
      observaciones: "Permiso y recepcion final de obras septiembre 2014."
    },
    {
      id: 3,
      imagenes: [
        "imagen1.jpg",
        "imagen2.jpg",
        "imagen3.jpg",
        "imagen4.jpg",
        "imagen5.jpg",
        "imagen6.jpg",
        "imagen7.jpg",
        "imagen8.jpg",
        "imagen9.jpg",
        "imagen10.jpg",
        "imagen11.jpg",
        "imagen12.jpg",
        "imagen13.jpg",
      ],
      nombre: "ARRIENDO LOCAL COMERCIAL CON PATENTE VIGENTE COMPRA VENTA DE VEHICULOS",
      direccion: "Avenida Vicuña Mackena 1341, Santiago",
      rol: "3038-025",
      precio: 0,
      descripcion: "Local Comercial excelente ubicación, en plena Avda. Vicuña Mackenna, frente a Megavisión a pasos de Metro Ñuble. LOCAL RECIÉN REMODELADO, autorizado para Compra y Venta de Vehículos y Taller de pintura y desabolladura, con amplias oficinas, baños, camarínes y bodegas.",
      estado: "Disponible",
      datos: {
        m2Terreno: "1400,00 m²",
        m2Construidos: "1645,09 m²",
        dimensionesTerreno: "12m frente x 77,40m fondo",
        primerPiso: "1445,57 m²",
        altillo: "199,52 m²",
        electricidad: "Enel Trifásica 10Kva",
        agua: "Agua potable y alcantarillado aguas andinas",
        usosPermitidos: "comercio en generalc, culto, cultura, deporte, educacion, esparcimiento, salud, estacionamientos, talleres señalados en tap 2a, compra y venta de vehiculos y accesorios, taller mecánico (solo con patente autorizada)",
        gas: "No tienefactibilidad metrogas",
        equipamientoIncluido: "Camara de pintura de vehículos",
        estacionamientos: 6
      },
      observaciones: "Local cuenta con permiso y recepción final, Certificado Resolución Sanitaria SESMA y Calificación Actividad de Taller Mecánico Inofensivo, Patente vigente de Compra y Venta de vehículos ytaller de reparación de pintura y desabolladura."
    },
    {
      id: 4,
      imagenes: [
        "imagen1.jpg",
        "imagen2.jpg"
      ],
      nombre: "ARRIENDO TALLER INOFENSIVO",
      direccion: "Avenida Padre Orellana 1912, Comuna De Santiago",
      rol: "3612-018",
      precio: 0,
      descripcion: "TALLER INOFENSIVO, excelente ubicación, a pasos de Avda. Vicuña Mackenna, y Metro Ñuble. RECIÉN REMODELADO, autorizado Código SII: 452002 Taller de  reparaciones de  Vehículos con  mantención  de  carrocerías. 400m2 totalmente construidos, galpón con acceso a dos calles Padre Orellana / Fray Camilo Henríquez, cuenta con cámarade pinturay pozo.",
      estado: "Arrendado",
      datos: {
        m2Terreno: "400,00 m²",
        m2Construidos: "400,00 m²",
        dimensionesTerreno: "10m frente x 40m fondo",
        primerPiso: "400 m²",
        altillo: "65 m²",
        m2Oficina: "66 m²",
        m2AreaLibre: "65 m²",
        electricidad: "Enel Trifásica 10Kva",
        agua: "Agua potable y alcantarillado aguas andinas",
        gas: "No tienefactibilidad metrogas",
        usosPermitidos: "Comercio en generalculto, cultura, deporte, educacion, esparcimiento, salud, estacionamientos, talleres señalados en tap 2a, compra y venta de vehiculos y accesorios, taller mecánico (solo con patente autorizada)",
        usosProhibidos: "Supermercadocompra y venta de vehiculosy accesorios, taller mecánico (nuevos)",
        estacionamientos: 6,
        equipamientoIncluido: "Camara de pintura de vehículos"
      },
      observaciones: "Local cuenta con permiso y recepción final, Certificado Resolución Sanitaria SESMA y Calificación Actividad de Taller Mecánico Inofensivo, Patente vigente de taller de reparación."
    }, {
      id: 5,
      imagenes: [
        "imagen1.jpg",
      ],
      nombre: "ARRIENDO OFICINAS CON BODEGA",
      direccion: "Calle Padre Orellana Nº 1581, Santiago",
      rol: "3394-24",
      precio: 0,
      descripcion: "Excelente ubicación, DESTINO OFICINAS COMERCIALES CON BODEGA, a pasos de Avda. Vicuña Mackenna y Metro Ñuble, Línea 5. Propiedad de dos pisos, planta acceso, área bodega y estacionamientos, oficinas, baños, camarines operarios. Segundo nivel oficinas privadas y plantas libres, sala de reuniones, baños y comedor administración.",
      estado: "No Disponible",
      datos: {
        m2Terreno: "202,00 m²",
        m2Construidos: "412,00 m²",
        dimensionesTerreno: "10,00m frente x 19/21,00m fondo",
        primerPiso: "202,00 m²",
        segundoPiso: "210,00 m²",
        "m2Baños/Servicios": "24,00 m²",
        "m2Estacionamiento/Bodega": "50,00 m²",
        m2OficinasPrimerPiso: "140,00 m²",
        m2OficinasSegundoPiso: "198,00 m²",
        electricidad: "Enel medido trifasico, tarifa BT 1 hasta 20Kva",
        usosPermitidos: "Zona D10 plan regulador comuna Santiago, usos suelos CUS 1, comercio en general, oficinas, talleres artesanales",
        agua: "Medidor agua potable y alcantarillado aguas andinas",
        gas: "Factibilidad gas natural sin empalme",
        usosProhibidos: "Talleres inofensivos, venta repuestos autos/motos y materiales de construccion, venta de maquinarias, motores y repuestos",
        equipamientoIncluido: "Equipos de aire acondicionado",
      },
      observaciones: "Propiedad ubicada en Área de Conservación Histórica, Barrio Plaza Bogotá. Estacionamiento para 2 vehículos en el interior."
    }, {
      id: 6,
      imagenes: [
        "imagen1.jpg",
        "imagen2.jpg",
        "imagen3.jpg",
        "imagen4.jpg",
        "imagen5.jpg",
        "imagen6.jpg",
        "imagen7.jpg",
      ],
      nombre: "ARRIENDO CASA APTA OFICINA COMERCIAL",
      direccion: "Portugal 1913, Santiago",
      rol: "3619-004",
      precio: 0,
      descripcion: "Excelente ubicación, casa antigua patrimonial de un piso, en calle Portugal, cercana a Barrio Franklin y a pasos de Metro Ñuble. Cuenta con amplios espacios para 5 oficinas, hall de ingreso o secretaria, bodega,  galería a patio techado, dos baños y comedor. Apta taller artesanal.",
      estado: "Disponible Inmediata",
      datos: {
        m2Terreno: "186,48 m²",
        m2Construidos: "186,48 m²",
        dimensionesTerreno: "8,40m frente x 22,20 fondo",
        caracteristica: "Fachada continua",
        m2Oficinas: "120,00 m²",
        m2Bodega: "40,00 m²",
        "m2Cocina/Baños/Pasillos": "26,48 m²",
        electricidad: "Enel potencia 9.87Kw",
        agua: "Agua potable y alcantarillado aguas andinas",
        usosPermitidos: "Comercio en general, cultura, deporte, talleres inofensivos, oficinas",
        gas: "No tiene",
        estacionamientos: "No tiene"
      },
      observaciones: "Casa ubicada en área de protección patrimonial"
    }, {
      id: 7,
      imagenes: [
        "imagen1.jpg",
        "imagen2.jpg",
        "imagen3.jpg",
        "imagen4.jpg",
        "imagen5.jpg",
        "imagen6.jpg",
        "imagen7.jpg",
        "imagen8.jpg",
        "imagen9.jpg",
        "imagen10.jpg",
        "imagen11.jpg",
        "imagen12.jpg",
        "imagen13.jpg",
        "imagen14.jpg",
      ],
      nombre: "ARRIENDO OFICINAS CON BODEGA ANEXA",
      direccion: "Calle Padre Orellana Nº 1577-1573  Santiago",
      rol: "3394-24 / 3394-23",
      precio: 0,
      descripcion: "Excelente ubicación, DESTINO OFICINAS COMERCIALES CON BODEGA, a pasos de Avda. Vicuña Mackenna y Metro Ñuble, Línea 5. Propiedad de dos pisos, planta acceso, área estacionamientos, baños, camarines y comedores operarios. Segundo nivel oficinas privadas y plantas libres, sala de reuniones, baños administración. Anexa conectada interiormente, propiedad contigua destinada a Bodega con bano apto accesibilidad universal.",
      estado: "Disponible Inmediata",
      datos: {
        m2Terreno: "218,40 m²",
        m2Construidos: "352,80 m²",
        dimensionesTerreno: "PO 1577 6,40m frente x 21,00m fondo\n PO 1574 4,00m frente x 21,00m fondo",
        primerPiso: "134,40 m² + 84,00m² = 218,40 m²",
        segundoPiso: "134,50 m²",
        m2Oficina: "108,95 m²",
        m2Bodega: "79,20 m²",
        m2BañosYServiciosPrimerPiso: "41,28 m²",
        m2BañosYserviciosSegundoPiso: "25,45 m²",
        electricidad: "Enel medidor monofasico",
        usosPermitidos: "Zona D10 plan regulador comuna Santiago, usos suelos CUS 1, comercio en general, oficinas, talleres artesanales",
        gas: "Metrogas",
        usosProhibidos: "Talleres inofensivos, venta repuestos auto/motos y materiales de construccion, venta de maquinarias, motores y repuestos",
        equipamientoIncluido: "Equipos de aire acondicionado, cerco eléctrico"
      },
      observaciones: "Dos Propiedades contiguas unidas interiormente, ubicadas en Área de Conservación Histórica, Barrio Plaza Bogotá. Estacionamiento para 2 vehículos en el interior. Iluminación LED."
    }, {
      id: 8,
      imagenes: [
        "imagen1.jpg",
        "imagen2.jpg",
        "imagen3.jpg",
        "imagen4.jpg",
        "imagen5.jpg",
        "imagen6.jpg"
      ],
      nombre: "ARRIENDO LOCAL COMERCIAL CON OFICINAS",
      direccion: "Calle Padre Orellana Nº 1585, Santiago",
      rol: "3394-25",
      precio: 0,
      descripcion: "Excelente ubicación, DESTINO COMERCIAL GALPON CON OFICINAS, a pasos de Av. Portugal, Vicuña Mackenna y línea 5, Metro Ñuble. Apto comercio, importadoras, con oficinas privadas, baños. Galpón con oficina en segundo piso, baños y camarines para operarios por separado. Planta libre para almacenamiento y/o estacionamientos. ",
      estado: "Ocupada",
      datos: {
        m2Terreno: "210,00 m²",
        m2Construidos: "235,60 m²",
        dimensionesTerreno: "10,00m frente x 21,00m fondo",
        primerPiso: "210,00 m²",
        segundoPiso: "25,60 m²",
        m2Oficina: "25,60 m²",
        m2Bodega: "184,00 m²",
        m2BañosYCamarines: "25,60 m² \n Separados damas y varones",
        electricidad: "Corriente monofasica tarifa BT1",
        usosPermitidos: "Zona D10 plan regulador comuna Santiago, usos suelo CUS 1, comercio en general, oficinas, talleres artesanales",
        agua: "Medidor agua potable y alcantarillado aguas andinas",
        gas: "No contempla",
        usosProhibidos: "Talleres inofensivos, venta repuestos autos/motos y materiales de construccion, venta de maquinarias, motores y repuestos",
        equipamientoIncluido: "Equipos de aire acondicionado, cerco eléctrico",
      },
      observaciones: "Propiedad ubicada en Área de Conservación Histórica, Barrio Plaza Bogotá. Estacionamiento interior."
    },  {
      id: 9,
      imagenes: [
        "imagen1.jpg",
        "imagen2.jpg",
        "imagen3.jpg",
        "imagen4.jpg",
        "imagen5.jpg",
        "imagen6.jpg",
        "imagen7.jpg"
      ],
      nombre: "ARRIENDO LOCAL COMERCIAL OFICINAS Y GALPON",
      direccion: "Calle Carmen Nº 1323 - 1325, Santiago",
      rol: "3073-6",
      precio: 0,
      descripcion: "Excelente ubicación, DESTINO COMERCIAL OFICINAS, Apto comercio, importadoras, con múltiples oficinas privadas, baños administración. Dos Galpones independientes para almacenamiento y estacionamientos. Área comedor, camarines y baños para personal. Acceso peatonal y entrada vehicular independiente.",
      estado: "No Disponible",
      datos: {
        m2Terreno: "554,66 m²",
        m2Construidos: "589,42 m²",
        dimensionesTerreno: "Carmen 1323 8,55m frente x 18,30m fondo\nCarmen 1325 11,00m frente x 36,20m fondo",
        primerPiso: "554,66 m²",
        altillo: "34,76 m²",
        superficieBodega: "195,80 m²",
        superficieOficina: "202,40 m²",
        galpoEstacionamiento: "156,46 m²\n 6 estacionamientos + porteria",
        electricidad: "Medidor trifasico tarifa BT1",
        agua: "1 medidor agua potable y alcantarillado aguas andinas",
        usosPermitidos: "Comercio en general excepto los indicados: Talleres artesanales",
        gas: "Proyecto gas x balon aprobado TC6",
        usosProhibidos: "Venta de repuestos y partes de vehiculos motorizados, venta de motores y maquinarias",
        equipamientoIncluido: "Porton automatico, citofono, baliza, apertura porton"
      },
      observaciones: "N/A"
    }, {
      id: 10,
      imagenes: [
        "imagen1.jpg",
        "imagen2.jpg",
        "imagen3.jpg"
      ],
      posicion: "-33.4648758,-70.6320147",
      nombre: "ARRIENDO BODEGA CON OFICINAS",
      direccion: "Calle Padre Orellana Nº1583, Santiago",
      rol: "3394-24",
      precio: 0,
      descripcion: "Excelente ubicación, DESTINO OFICINAS CON BODEGA, a pasos de Avda. Vicuña Mackenna y Metro Ñuble, Línea 5. Propiedad de dos pisos (Altillo utilizable), planta acceso, área bodega y estacionamientos, oficinas, baños, camarines operarios. Segundo nivel plantas libre bodega.",
      estado: "Disponibilidad Inmediata",
      datos: {
        m2Terreno: "200,00m²",
        m2Construidos: "374,00 m²",
        dimensionesTerreno: "10,00m. frente x 20,00m fondo",
        primerPiso: "200,00 m²",
        segundoPiso: "174,00 m²",
        m2BañosYservicios: "14,00m²",
        m2Estacionamiento:  "81,00m²",
        m2OficinasPrimerPiso: "105,00m²",
        m2BodegaSegundoPiso: "174,00 m²",
        electricidad: "Medidor Monofasico Enel Tarifa Bt",
        agua: "Medidor Agua Potable Y Alcantarillado Aguas Andinas",
        usosPermitidos: "Comercio en general, Oficinas Y Talleres Artesanales",
        gas: "Factibilidad gas natural sin empalme",
        usosProhibidos: "Talleres Inofensivos, Venta Repuestos Autos/motos Y Materiales De Construccion Venta De Maquinarias, Motores Y Repuestos",
        equipamientoIncluido: "Equipos de Aire Acondicionado y Cerco Eléctrico"
      },
      observaciones: "Propiedad ubicada en Área de Conservación Histórica, Barrio Plaza Bogotá. Estacionamiento para 2 vehículos en el interior"
    }, {

      id: 11,
      imagenes: [
        "imagen1.jpg",
        "imagen2.jpg",
        "imagen3.jpg",
        "imagen4.jpg",
        "imagen5.jpg"
      ],
      nombre: "ARRIENDO LOCAL COMERCIAL BODEGA",
      direccion: "Arauco Nº 149, Santiago",
      rol: "",
      precio: 0,
      descripcion: "Excelente ubicación, DESTINO COMERCIAL, BODEGA, a pasos de Vicuña Mackenna y Metro Ñuble. Apto comercio, importadoras, con Galpón para almacenamiento y estacionamientos. Acceso entrada vehicular",
      estado: "Disponibilidad Inmediata",
      datos: {
        altillo: "00,40 m2",
        electricidad: "1 medidores BT1",
        agua: "Medidor agua potable y alcantarillado aguas andinas"
      },
      observaciones: ""
    }, {
      id: 12,
      imagenes: [
        "imagen1.jpg",
        "imagen2.jpg",
        "imagen3.jpg",
        "imagen4.jpg",
        "imagen5.jpg",
        "imagen6.jpg",
        "imagen7.jpg"
      ],
      nombre: "ARRIENDO LOCAL COMERCIAL OFICINAS Y GALPON",
      direccion: "Calle Carmen Nº 1784, Santiago",
      rol: "3435-24",
      precio: 0,
      descripcion: "Excelente ubicación, DESTINO COMERCIAL OFICINAS, Apto comercio, importadoras, con oficinas privadas, baño administración. Galpón para almacenamiento y estacionamientos. Área baño para personal para bodegaje.",
      estado: "Arrendado",
      datos: {
        m2TerrenoTotal: "250,00m2",
        dimensionesTerreno: "19,55m frente x 11,00m y 8,55m fondo",
        m2Construidos: "250,00 m2",
        electricidad: "1 medidor BT1 potencia 2,85 kw",
        usosPermitidos: "Comercio en general, talleres artesanales",
        agua: "1 medidor agua potable y alcantarillado aguas andinas"
      },
      observaciones: ""
    }, {
      id: 13,
      imagenes: [
        "imagen4.jpg",
        "imagen1.jpg",
        "imagen2.jpg",
        "imagen3.jpg",
        "imagen5.jpg",
        "imagen6.jpg",
        "imagen8.jpg",
        "imagen9.jpg",
        "imagen10.jpg"
      ],
      nombre: "ARRIENDO OFICINA Nº 313",
      direccion: "Av. Nueva Providencia Nº 1945, Providencia",
      rol: "00625-00037",
      precio: 0,
      descripcion: "Excelente ubicación a la salida del metro Pedro de Valdivia (Línea 1), DESTINO COMERCIAL, 2 oficinas privadas con baño, planta libre de entrada con baño, Kichinet. Bodega para almacenamiento. Acceso peatonal por Av. Nueva Providencia 1945 y un estacionamiento con entrada vehicular por Marchant Pereira. Consejería día y noche.",
      estado: "Arrendado",
      datos: {
        oficina: "100,00 m2",
        bodega: "18,00m2",
        electricidad: "1 medidor BT1 potencia 1,2 kw",
        agua: "1 medidor agua potable y alcantarillado aguas andinas",
        usosPermitidos: "Tareas administrativas y talleres artesanales"
      },
      observaciones: "Cuenta con estacionamiento"
    }, {
      id: 14,
      imagenes: [
        "imagen1.jpg"
      ],
      nombre: "ARRIENDO LOCAL COMERCIAL CON OFICINAS",
      direccion: "Av. Vicuña Mackenna Nº 1621, Santiago",
      rol: "3388-004",
      precio: 0,
      descripcion: "Excelente ubicación, DESTINO COMERCIAL, SALON, BODEGAS, OFICINAS, BAÑO para personal. En Av. Vicuña Mackenna con una superficie de 257mt², apto para local de ventas, acceso por Av. Vicuña Mackenna Nº 1621.",
      estado: "Arrendado",
      datos: {
        m2Terreno: "257,00m2",
        m2Construidos: "257,0 m2",
        electricidad: "2 medidores BT1",
        agua: "1 medidor agua potable y alcantarillado aguas andinas",
        usosPermitidos: "Comercio en general"
      },
      observaciones: ""
    }
  ];

  @Emit("select-local")
  onSelectLocal(doc: Local) {
    return doc;
  }
}
