






































import {Component, Vue} from "vue-property-decorator";
import Toolbar from "@/components/Toolbar.vue";
@Component({
  components: {Toolbar}
})
export default class Portada extends Vue {

}
