const formatMoney = (value: string) => {
  const reverse = parseInt(value)
    .toString()
    .split("")
    .reverse()
    .join("");
  const moneyReverse = reverse.match(/.{1,3}/g);
  if (moneyReverse) {
    return (
      "$ " +
      moneyReverse
        .join(".")
        .split("")
        .reverse()
        .join("")
    );
  } else {
    return "error";
  }
};

export { formatMoney };
