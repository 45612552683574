









































import {Component, Vue} from "vue-property-decorator";

@Component
export default class Toolbar extends Vue {
  botones = [
    {
      id: "sobre",
      text: "¿Quienes somos?"
    }, {
      id: "arriendos",
      text: "Arriendos"
    }, {
      id: "contacto",
      text: "Contacto"
    }
  ];

  goTo(id: string) {
    this.$vuetify.goTo(`#${id}`);
  }
}
