


























import {Component, Vue} from "vue-property-decorator";

@Component
export default class Seguridad extends Vue {
  items = [
    {
      id: "item1",
      icon: "mdi-heart",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus orci ex, pulvinar nec finibus ut"
    }, {
      id: "item1",
      icon: "mdi-heart",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus orci ex, pulvinar nec finibus ut"
    }, {
      id: "item1",
      icon: "mdi-heart",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus orci ex, pulvinar nec finibus ut"
    }, {
      id: "item1",
      icon: "mdi-heart",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus orci ex, pulvinar nec finibus ut"
    }
  ]
}
