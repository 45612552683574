






















































































































































import {Component, Vue} from "vue-property-decorator";

@Component
export default class Contacto extends Vue {
  nombre = "";
  telefono = "";
  email = "";
  asunto = "";
  mensaje = "";
  asuntos = [
    "Consultas sobre arriendos",
    "Consultas generales"
  ];

  capitalize(text: string) {
    return text.replace(/(^|\s)[a-zñ]/g, result => result.toUpperCase());
  }

  enviar() {
    if (this.nombre && this.telefono && this.email && this.asunto && this.mensaje) {
      const mensaje = {
        to: "cesar.douglas@bestdata.cl",
        message: {
          subject: `Mensaje de: ${this.capitalize(this.nombre)}`,
          html: `
            <p>Nombre: ${this.capitalize(this.nombre)}</p>
            <p>Teléfono: ${this.telefono}</p>
            <p>Email: ${this.email}</p>
            <p>Asunto: ${this.asunto}</p>
            <p>Mensaje: ${this.mensaje}</p>
          `
        }
      };
      //this.$store.dispatch("mail/set", mensaje);
      this.nombre = "";
      this.telefono = "";
      this.email = "";
      this.asunto = "";
      this.mensaje = "";
    }
  }
}
