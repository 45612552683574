






























































































































































































import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {Local} from "@/typings/store";
import {formatMoney} from "@/helper/money";

@Component
export default class Detalle extends Vue {
  @Prop() readonly local!: Local;

  formatMoney = formatMoney;

  @Emit("close-dialog")
  onCloseDialog() {
    return null;
  }

  contactar() {
    this.$vuetify.goTo("#contacto");
    this.onCloseDialog();
  }
}
