import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Inicio from "@/views/Inicio.vue";
import CrearFicha from "@/views/CrearFicha.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Inicio',
    component: Inicio
  },
  {
    path: "/crearFicha",
    name: "CrearFicha",
    component: CrearFicha
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes
})

export default router
