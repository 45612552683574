


















import {Component, Vue} from "vue-property-decorator";

@Component
export default class CrearFicha extends Vue {
  texto = "";
  result = "";

  crear() {
    const split = this.texto.split(/\s{2,}/g);
    let i = 0;
    const varName = split[0].split(" ").map(value => {
      let temp = "";
      if(i > 0) {
        temp = value[0].toUpperCase() + value.slice(1, value.length).toLowerCase();
      } else {
        temp = value.toLowerCase();
      }
      i++;
      return temp;
    }).join("");
    this.result +=  `${this.result ? ',\n' : ''}${varName}: "${split[1].toLowerCase().trim()}"`;
    this.texto = "";
  }
}
